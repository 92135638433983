import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { rem } from '@/app/common/style/functions';
import { desktopDown } from '@/app/common/style/mixins';
import { COLOR, FONTS } from '@/app/common/style/variables';

export const Wrapper = styled.section`
  margin: 0;
  transition: 0.2s opacity ease;

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
    max-height: 0;
    overflow: hidden;
  }

  .contentful-section-links {
    padding-top: 80px;

    ul {
      display: block;

      li {
        display: inline-block;
        margin: 0 32px;

        a {
          display: inline-block;
          text-decoration: none;
          font-size: ${rem(22)};
          font-weight: ${FONTS.light};
          line-height: ${rem(30)};
          color: ${COLOR.woodSmoke1};
          letter-spacing: 0.5px;
          padding: 0;
          position: relative;

          &:after {
            content: '';
            display: block;
            border-bottom: 1px ${COLOR.woodSmoke1} solid;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            transition: 0.4s opacity ease;
          }

          &:hover {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
    &.header-links-left {
      float: left;

      li:first-of-type {
        margin-left: 0;
      }
    }

    &.header-links-right {
      float: right;

      li:last-of-type {
        margin-right: 0;
      }
    }

    ${desktopDown(css`
      display: none;
    `)}
  }

  .contentful-section-logo {
    img {
      width: auto;
      height: 54px;
    }

    ${desktopDown(css`
      display: none;

      img {
        height: 48px;
      }

      .alignment-center {
        > div {
          left: 0;
          transform: none;
        }
      }

      .page-home & {
        display: block;
      }
    `)}
  }
`;

import React, { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx';

import { Sections } from '@/components/sections/Section';
import { LinksSection } from '@/components/sections/links/Links';
import { LogoSection } from '@/components/sections/logo/Logo';
import { useContext } from '@/components/app/context';
import { Wrapper } from './styles';
import { ContentfulSection } from '../types.d';

export interface HeaderSection extends ContentfulSection {
  sections?: [LinksSection | LogoSection];
}

/**
 * Header section
 */
const Header: FC<HeaderSection> = ({ sections = [] }) => {
  const [
    {
      page: {
        options: { hideHeader },
      },
    },
    dispatch,
  ] = useContext();
  const { inView, ref } = useInView();

  useEffect(() => {
    dispatch({
      type: `ToggleHeaderFocus`,
      payload: inView,
    });
  }, [dispatch, inView]);

  return (
    <Wrapper className={clsx({ 'is-hidden': hideHeader })} ref={ref}>
      <div className="container">
        <Sections ignoreContainer sections={sections} />
      </div>
    </Wrapper>
  );
};

export default Header;
